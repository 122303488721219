input[type=checkbox] {
    margin-right: 5px;
    position: relative;
    vertical-align: -1px;
}

@-moz-document url-prefix() {
    input[type=checkbox] {
        vertical-align: 1px;
    }
}

textarea:focus, input:focus {
    outline: none;
    box-shadow: none !important;
}

.btn-xs {
    height: 26px;
    padding-top: 0 !important;
    padding-bottom: 0;
    font-size: .8rem;
    line-height: 1.4rem !important;
}

.btn-icon {
    height: 2.15em;
    line-height: 1.15em;
}

.btn {
    outline: none;
    box-shadow: none !important;
}

label {
    margin-bottom: 2px !important;
    padding-left: 2px !important;
    line-height: 1rem;
    font-size: .9rem;
    display: block
}

.form-group {
    margin-bottom: 12px !important;
}

.form-control::-webkit-input-placeholder {
    opacity: .4;
}

.form-control::-moz-placeholder {
    opacity: .4;
}

.form-control:-ms-input-placeholder {
    opacity: .4;
}

.form-control::placeholder {
    opacity: .4;
}

input.form-control {
    padding-top: 4px;
    padding-bottom: 4px;
    vertical-align: top;
    line-height: 1.5em;
}

.slick-drop-down .btn {
    padding: 4px 8px 4px 8px;
}

.input-group-text {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.h-100 {
    height: 1px;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.table-bordered thead tr:not(:first-child) td:not(:last-child):not(:first-child),
.table-bordered thead tr:not(:first-child) td:not(:last-child):not(:first-child) .resize_column_left,
.table-bordered thead tr:not(:first-child) td:not(:last-child):not(:first-child) .resize_column_right {
    border-right: none;
    border-left: none;
}

.table-bordered thead tr:not(:first-child) td:not(:last-child),
.table-bordered thead tr:not(:first-child) td:not(:last-child) .resize_column_left {
    border-right: none;
}

.table-bordered thead tr:not(:first-child) td:not(:first-child),
.table-bordered thead tr:not(:first-child) td:not(:first-child) .table-bordered {
    border-left: none;
}

.table-bordered tbody td:not(:last-child) {
    border-right: none !important;
}

.table-bordered tbody td:not(:first-child) {
    border-left: none;
}
